<template>
  <div
    class="modal modal__right"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    v-if="show"
    :class="{ show: show == true }"
    :style="{ display: 'block' }"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a class="modal__close modal__close--black" @click="closeModal">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              {{ isEditing ? "Edit" : "Send" }} Notification
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label" for="notification_name"
                      >Name</label
                    >
                    <input
                      id="notification_name"
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Write your task summary"
                      v-model="notificationName"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Select Notification Type</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="notificationTypes"
                    >
                      <option value="__Select option__" disabled selected>
                        __Select option__
                      </option>
                      <option value="Sms">SMS</option>
                      <option value="Email">Email</option>
                    </select>
                  </div>
                </div>
              </div>

              <ul id="accordion">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#conditions"
                    aria-expanded="false"
                    aria-controls="conditions"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      conditions
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="conditions"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">target</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="notificationTarget"
                          @change="activateEvent"
                        >
                          <option value="" disabled selected>
                            Select type
                          </option>
                          <option value="Customers">Customers</option>
                          <option value="Groups">Groups</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#notes"
                    aria-expanded="false"
                    aria-controls="notes"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Content
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="notes" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          id=""
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Template Body"
                          v-model="templateBody"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="row">
                <div class="col-12">
                  <div class="form__item">
                    <label
                      for="template_state"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Use Template
                      <input
                        type="checkbox"
                        id="template_state"
                        class="form-"
                        v-model="isTemplateSelected"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>

                <transition name="slide-fade" appear>
                  <div class="col-12" v-if="isTemplateSelected">
                    <div class="form__item">
                      <label class="form__label">Use Template</label>
                      <select
                        class="select select--lg"
                        required
                        v-model="selectedTemplate"
                        @change="getSelectedTemBody(selectedTemplate)"
                      >
                        <option value="__Select option__" disabled selected>
                          __Select Template__
                        </option>
                        <option
                          v-for="(template, index) in allTemplates"
                          :key="index"
                          :value="template"
                        >
                          {{ template.templateName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </transition>

                <div class="col-12" v-if="isTemplateSelected">
                  <div>
                    <div class="form__item">
                      <textarea
                        id=""
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Template"
                        v-model="notificationTemplateContent"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- <div class="col-12">
                  <div class="form__item">
                    <label
                      for="customer_group"
                      class="
                        has-checkbox
                        page__body__header__label
                        text--capital
                        mb-3
                        text--black
                      "
                      >Use Predefined Customer Group
                      <input
                        type="checkbox"
                        id="customer_group"
                        class="form-"
                        v-model="isPredefinedGroupSelected"
                        disabled
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div> -->

                <transition name="slide-fade" appear>
                  <div class="col-6">
                    <div class="form__item">
                      <label class="form__label"
                        >Select Notification Group</label
                      >
                      <select
                        class="select select--lg"
                        required
                        v-model="selectedUser"
                        @change="changeSearchParams"
                      >
                        <option value="__Select option__" disabled selected>
                          __Select Users__
                        </option>
                        <option value="Customers">Customers</option>
                        <option value="Groups">Groups</option>
                        <option value="preNotifGroup">
                          Predefined Notification Groups
                        </option>
                      </select>
                    </div>
                  </div>
                </transition>

                <transition name="slide-fade" appear>
                  <div class="col-6" v-if="isPredefinedCustomerSelectedValue">
                    <label class="form__label">Select Customers</label>
                    <multiselect
                      v-model="assignedPerson"
                      id="ajax"
                      label="customerFirstName"
                      track-by="customerFirstName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="true"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      :loading="loadingCustomersList"
                      @input="getSelectedNotificationValues"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span
                            >{{ option.customerFirstName }}
                            {{ option.customerLastName }}</span
                          >
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </transition>

                <transition name="slide-fade" appear>
                  <div class="col-6" v-if="isPredefinedGroupSelectedValue">
                    <label class="form__label">Select Groups</label>
                    <multiselect
                      v-model="assignedPerson"
                      id="ajax"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="groups"
                      :multiple="true"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      :loading="loadingGroupsList"
                      @input="getSelectedNotificationValues"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </transition>

                <transition name="slide-fade" appear>
                  <div
                    class="col-6"
                    v-if="isPredefinedNotificationGroupSelected"
                  >
                    <label class="form__label">Select Notification Group</label>
                    <multiselect
                      v-model="assignedPerson"
                      id="ajax"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="notifGroups"
                      :multiple="true"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="getSelectedNotificationValues"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </transition>
              </div>

              <div class="row mt-5">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Placeholder</label>
                    <multiselect
                      v-model="selectedMenu"
                      id="linked"
                      label="plainName"
                      track-by="plainName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="custommenus"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.plainName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Placeholder text</label>
                    <span class="input form__input form__input--lg">
                      {{ name }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    @click.prevent="sendNotification"
                  >
                    send Notification
                  </button>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import Multiselect from "vue-multiselect";
export default {
  name: "createNotification",
  props: {
    close: Function,
    show: Boolean,
    populatedNotification: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: () => ({}),
    },
  },
  data() {
    return {
      isTemplateSelected: false,
      isPredefinedGroupSelected: true,
      allTemplates: [],
      selectedTemplate: {},
      selectedUser: "",
      notifName: "",
      customer: {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
      },
      customers: [],
      groups: [],
      assignedPerson: [],
      loading: false,
      notificationType: "",
      individualDetails: [],
      target: "",
      showEvents: false,
      event: "",
      notifGroups: [],
      custommenus: [],
      selectedMenu: null,
      notificationTemplateContent: "",
      createNotificationForm: {
        name: "",
        notificationType: "",
        target: "",
        content: "",
        isPredefinedNotificationGroup: false,
        isCustomer: false,
        isGroup: false,
        predefinedNotificationGroup: [],
        notificationGroup: [],
        notificationCustomers: [],
        createdBy: "",
      },
      loadingCustomersList: false,
      loadingGroupsList: false,
    };
  },
  components: {
    Multiselect,
  },
  watch: {
    populatedNotification(newVal) {
      if (newVal) {
        return this.getRecipient(newVal);
      }
    },
  },
  computed: {
    isPredefinedCustomerSelectedValue() {
      return this.selectedUser === "Customers" ? true : false;
    },
    isPredefinedGroupSelectedValue() {
      return this.selectedUser === "Groups" ? true : false;
    },
    isPredefinedNotificationGroupSelected() {
      return this.selectedUser === "preNotifGroup" ? true : false;
    },
    isSelectedTemplateEmpty() {
      return Object.keys(this.selectedUser).length === 0;
    },
    isEditingNotification() {
      return Object.keys(this.populatedNotification).length === 0;
    },
    notificationName: {
      get() {
        return !this.isEditingNotification &&
          this.createNotificationForm.name === ""
          ? this.populatedNotification.eventName
          : this.createNotificationForm.name;
      },
      set(newTemplateName) {
        this.createNotificationForm.name = newTemplateName;
      },
    },
    notificationTypes: {
      get() {
        return !this.isEditingNotification &&
          this.createNotificationForm.notificationType === ""
          ? this.populatedNotification.notificationType
          : this.createNotificationForm.notificationType;
      },
      set(newTemplateType) {
        this.createNotificationForm.notificationType = newTemplateType;
      },
    },
    notificationTarget: {
      get() {
        return !this.isEditingNotification &&
          this.createNotificationForm.target === ""
          ? this.populatedNotification.notificationTarget
          : this.createNotificationForm.target;
      },
      set(newTemplateTarget) {
        this.createNotificationForm.target = newTemplateTarget;
      },
    },
    templateBody: {
      get() {
        return !this.isEditingNotification &&
          this.createNotificationForm.content === ""
          ? this.populatedNotification.messageBody
          : this.createNotificationForm.content;
      },
      set(tempBody) {
        this.createNotificationForm.content = tempBody;
      },
    },
    name: {
      get() {
        return this.selectedMenu ? "{" + this.selectedMenu?.column + "}" : "";
      },
    },
  },
  methods: {
    resetForm() {
      this.createNotificationForm = {
        name: "",
        notificationType: "",
        target: "",
        content: "",
        isPredefinedNotificationGroup: false,
        isCustomer: false,
        isGroup: false,
        predefinedNotificationGroup: [],
        notificationGroup: [],
        notificationCustomers: [],
        createdBy: "",
      };
      this.notificationTemplateContent = "";
      this.showEvents = false;
      this.isTemplateSelected = false;
      this.selectedUser = "";
      this.assignedPerson = [];
      this.selectedTemplate = "";
    },
    activateEvent() {
      this.showEvents = true;
    },
    getSelectedTemBody(template) {
      this.notificationTemplateContent = template.templateBody;
    },
    limitText(count) {
      return `and ${count} other customers`;
    },
    changeSearchParams() {
      this.assignedPerson = [];
      if (this.selectedUser === "Customers") {
        this.createNotificationForm.isPredefinedNotificationGroup = false;
        this.isCustomer = true;
        this.isGroup = false;
        this.getCustomers();
      } else if (this.selectedUser === "Groups") {
        this.createNotificationForm.isPredefinedNotificationGroup = false;
        this.isCustomer = false;
        this.isGroup = true;
        this.getGroups();
      } else if (this.selectedUser === "preNotifGroup") {
        this.createNotificationForm.isPredefinedNotificationGroup = true;
        this.isCustomer = false;
        this.isGroup = false;
        this.fetchNotificationGroups();
      }
    },
    getRecipient(notification) {
      this.getCustomers();
      this.getGroups();
      this.fetchNotificationGroups();
      this.createNotificationForm.isGroup = notification.isGroup;
      this.createNotificationForm.isCustomer = notification.isCustomer;
      this.createNotificationForm.isPredefinedNotificationGroup =
        notification.isPredefinedGroup;
      if (this.createNotificationForm.isGroup) {
        this.selectedUser = "Groups";
        this.groups.forEach((item) => {
          if (notification.notificationRecipients.includes(item.groupKey)) {
            this.createNotificationForm.notificationGroup.push(item.groupKey);
            this.assignedPerson.push(item);
          }
        });
        return;
      }

      if (this.createNotificationForm.isCustomer) {
        this.selectedUser = "Customers";
        this.customers.forEach((item) => {
          if (notification.notificationRecipients.includes(item.customerKey)) {
            this.createNotificationForm.notificationCustomers.push(
              item.customerKey
            );
            this.assignedPerson.push(item);
          }
        });
        return;
      }

      if (this.createNotificationForm.isPredefinedNotificationGroup) {
        this.selectedUser = "preNotifGroup";
        this.notifGroups.forEach((item) => {
          if (
            notification.notificationRecipients.includes(item.notificationKey)
          ) {
            this.createNotificationForm.predefinedNotificationGroup.push(
              item.notificationKey
            );
            this.assignedPerson.push(item);
          }
        });
        return;
      }
    },
    getSelectedNotificationValues() {
      if (this.isPredefinedCustomerSelectedValue) {
        let data = [];
        this.assignedPerson.forEach((item) => {
          data.push(item.customerKey);
        });
        this.createNotificationForm.notificationCustomers = data;
      }

      if (this.isPredefinedGroupSelectedValue) {
        let data = [];
        this.assignedPerson.forEach((item) => {
          data.push(item.groupKey);
        });
        this.createNotificationForm.notificationGroup = data;
      }

      if (this.isPredefinedNotificationGroupSelected) {
        let data = [];
        this.assignedPerson.forEach((item) => {
          data.push(item.notificationKey);
        });
        this.createNotificationForm.predefinedNotificationGroup = data;
      }
    },
    getAllTemplates() {
      ApiService.get("Template/gettemplates/templateName/1/100000").then(
        (response) => {
          this.allTemplates = response.data.data.data;
        }
      );
    },
    getCustomers() {
      this.loadingCustomersList = true;
      GeneralApiService.post("Customer/customers", this.customer).then(
        (resp) => {
          this.customers = resp.data.data.items;
          this.loadingCustomersList = false;
        }
      );
    },
    getGroups() {
      this.loadingGroupsList = true;
      GeneralApiService.post("Group/Groups", this.customer).then((resp) => {
        this.groups = resp.data.data.items;
        this.loadingGroupsList = false;
      });
    },
    fetchNotificationGroups() {
      ApiService.post(
        "NotificationMessage/AllPredefinedNotificationGroup?pageIndex=1&pageSize=500000"
      ).then((resp) => {
        this.notifGroups = resp.data.data.data;
      });
    },
    fetchGroups(query) {
      GeneralApiService.get(`Group/search/${query}`).then((resp) => {
        this.customers = resp.data.data.items;
      });
    },
    sendNotification() {
      this.loading = true;
      if (this.isEditing) {
        let data = { ...this.createNotificationForm };
        data.name = this.createNotificationForm.name
          ? this.createNotificationForm.name
          : this.populatedNotification.eventName;
        data.content = this.createNotificationForm.content
          ? this.createNotificationForm.content
          : this.populatedNotification.messageBody;
        data.target = this.createNotificationForm.target
          ? this.createNotificationForm.target
          : this.populatedNotification.notificationTarget;
        data.notificationType = this.createNotificationForm.notificationType
          ? this.createNotificationForm.notificationType
          : this.populatedNotification.notificationType;
        data.notificationKey = this.populatedNotification.notificationKey;
        this.updateNotification(data);
      } else {
        this.createNotification(this.createNotificationForm);
      }
    },
    createNotification(data) {
      ApiService.post("NotificationMessage", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("sent-notification-successfully", resp.data.message);
            this.closeModal();
          } else {
            this.loading = false;
            this.$emit("error-sending-notification", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-sending-notification", error.resp.data.message);
        });
    },
    updateNotification(data) {
      ApiService.put("NotificationMessage", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("sent-notification-successfully", resp.data.message);
            this.closeModal();
          } else {
            this.loading = false;
            this.$emit("error-sending-notification", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-sending-notification", error.response.data.message);
        });
    },
    sendNotif(data) {
      ApiService.post("NotificationMessage/sendTemplateEmail", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("sent-notification-successfully", resp.data.message);
            this.$emit("close");
            this.resetForm();
          } else {
            this.loading = false;
            this.$emit("error-sending-notification", resp.data.message);
            this.resetForm();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-sending-notification", error.resp.data.message);
        });
    },
    getGroupDetails() {
      this.individualDetails = [];
      GeneralApiService.get(`Group/${this.assignedPerson.groupKey}`).then(
        (resp) => {
          this.individualDetails = resp.data.data;
        }
      );
    },
    getCustomerDetail() {
      this.individualDetails = [];
      GeneralApiService.get(`Customer/${this.assignedPerson.customerKey}`).then(
        (resp) => {
          this.individualDetails = resp.data.data;
        }
      );
    },
    sendSMSNotif(data) {
      ApiService.post("NotificationMessage/SendSms", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("sent-notification-successfully", resp.data.message);
            this.closeModal();
          } else {
            this.loading = false;
            this.$emit("error-sending-notification", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-sending-notification", error.resp.data.message);
        });
    },
    sendPlainEmail(data) {
      ApiService.post("NotificationMessage/sendSimpleMail", data)
        .then((resp) => {
          if (resp.data.status !== false) {
            this.loading = false;
            this.$emit("sent-notification-successfully", resp.data.message);
            this.$emit("close");
            this.resetForm();
          } else {
            this.loading = false;
            this.$emit("error-sending-notification", resp.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$emit("error-sending-notification", error.resp.data.message);
        });
    },
    getCustomMenus() {
      GeneralApiService.get("CustomMenu/all-custom-menus").then((resp) => {
        this.custommenus = resp.data.data;
      });
    },
    closeModal() {
      this.resetForm();
      this.$emit("close");
    },
  },
  mounted() {
    this.getAllTemplates();
    this.getCustomers();
    this.getGroups();
    this.fetchNotificationGroups();
    this.getCustomMenus();
  },
};
</script>
